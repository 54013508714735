import React from 'react';


const TermsAndConditions = () => {

  return (

    <div style={{display:'flex',flex:1, flexDirection:'column'}}>
      <div style={{ width: '100%', height: '70px', borderBottom: '1px solid #000' , paddingLeft:20}}>
      <img src={'/img/logo.png'} style={{color:'green'}}/>
      </div>
      <div style={{padding:'20px',}}>

      <h2>Terms & Conditions for Sundarini Naturals Mobile App</h2>

      <h3>1. Welcome to Sundarini Naturals</h3>

      <p>Thank you for choosing the Sundarini Naturals Mobile App (the App). By downloading, installing, or using the App, you agree to comply with these Terms & Conditions. If you disagree with any part of these terms, please refrain from using the App.</p>
      <h3>2. Purpose of the App</h3>
      <p>The App is designed to simplify your experience with Sundarini Naturals. You can subscribe to and manage your product orders, track deliveries, and explore our range of offerings—all at your fingertips.</p>
      <h3>3. Subscription Management</h3>
      <h3>3.1 Subscription Services</h3>
      <p>Through the App, you can subscribe to Sundarini Naturals products. By doing so, you agree to receive regular deliveries according to the schedule and frequency you select.</p>
      <h3>3.2 Cancellation Policy</h3>
      <p>Same-Day Cancellation: Unfortunately, cancellations on the day of delivery aren not possible. Please plan your subscriptions accordingly.</p>
      <p>Cancellation Process: Cancel before 9pm through the App. Ensure cancellations are made at least one day before the next scheduled delivery to avoid charges.</p>

      <h3>3.3 Pause Your Subscription</h3>
      <p>Need a break? You can temporarily pause your subscription through the App before 9pm. Make sure to do so at least one day before the next delivery.</p>

      <h2>4. Refund Policy</h2>
      <p>Refunds for eligible cancellations will be processed within 7-10 business days. Same-day cancellations do not qualify for refunds.</p>

      <h2>5. Customer Support</h2>
      <p>We&apos;re here to help! For any issues or inquiries, you can reach our customer support team directly through the App, by calling +91 7908396997 from 6am to 9pm or by emailing us at customercare@sundarini.organic.</p>

      <h2>6. Complaints Handling</h2>
      <p>Only same-day complaints will be addressed. Complaints must be accompanied by proper evidence (photos, videos, etc.). If necessary, we reserve the right to inspect the product before processing any refunds.</p>

      <h2>7. Delivery Policy</h2>
      <p>Orders can be delivered at any time within the selected delivery time slot. We may not be able to adhere to specific delivery times requested by users. Delivery may be delayed due to unforeseen circumstances (such as weather, traffic, etc.).</p>

      <h2>8. Changes to Terms &amp; Conditions</h2>
      <p>Sundarini Naturals reserves the right to update these Terms &amp; Conditions at any time. Changes will take effect immediately upon posting within the App. Continued use of the App after such changes indicates your acceptance of the updated terms.</p>

      <h2>9. Disclaimer</h2>
      <p>The App is provided as Sundarini Naturals is not responsible for any errors, omissions, or interruptions in service. We do our best, but no guarantees are made.</p>

      <h2>10. Termination of Service</h2>
      <p>We reserve the right to terminate or suspend your access to the App at any time, without notice, if we believe your actions violate these Terms &amp; Conditions or harm others.</p>

      <h2>11. Contact Us</h2>
      <p>For further details or questions regarding these Terms &amp; Conditions, reach out to Sundarini Naturals customer support at +91 7908396997 or customercare@sundarini.organic.</p>
    </div>
    </div>
  );
};

export default TermsAndConditions;